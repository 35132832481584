const toFormattedStringWithThousands = (value, nullvalue = "") => {
        if (value == null) {
            return nullvalue;
        }
        // Format with German thousands and decimals separator
        // Inspired by https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
        let parts = value.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return parts.join(",");
}

const isValidNumber = (value, precision) => {
    /* Validate input, allow empty values */
    if(precision == null) {
        precision = 2;
    }
    if (value == null) {
        return true;
    }
    if (value == "") {
        return true;
    }
    return RegExp("^(\\-|\\+)?[0-9.]*,?[0-9]{0,"+precision+"}$")
        .test(value)
}

export default {
    toCents(value, precision) {
        if(precision == null) {
            precision = 2;
        }
        if (!isValidNumber(value, precision)) {
            return NaN;
        }
        let value_c = value.replace(".","").replace(",",".");
        let value_f = parseFloat(value_c);
        return Math.round(value_f * Math.pow(10, precision), 0);
    },
    isValidNumber,
    toFormattedStringWithThousands,
    toCurrency(centsValue, precision) {
        if (centsValue == null) {
            return '';
        }
        if(!precision) {
            precision = 2;
        }
        const value = (centsValue/Math.pow(10, precision)).toFixed(precision);

        //return value.toString().replace('.', ',');
        return toFormattedStringWithThousands(value);
    }
}
