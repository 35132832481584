import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

import auth from './modules/auth';
import loading from './modules/loading';
import users from './modules/users';
import callForBids from './modules/callforbids';
import questionnaires from './modules/questionnaires';
import invitations from './modules/invitations';
import contracts from './modules/contracts';
import projects from './modules/projects';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
    state: {
    },
    actions: {
        clearAll({commit, dispatch}) {
            dispatch('callForBids/clear');
            dispatch('users/clear');
            dispatch('questionnaires/clear');
            dispatch('invitations/clear');
            dispatch('contracts/clear');
            dispatch('projects/clear');
        }
    },
    mutations: {
    },
    modules: {
        auth,
        loading,
        users,
        callForBids,
        contracts,
        questionnaires,
        invitations,
        projects
    }
});
