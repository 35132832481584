<template>
    <div class="card" style="border-width: 0px; box-shadow: none;">
        <div class="card-header px-0" style="background-color: #fff">
            {{ $t("invitation.listheader") }}
        </div>
        <div class="card-body px-0" style="background-color: #fff">
            <b-card :title="$t(`invitation.card_list`)" class="mb-3">
                <b-button v-b-toggle.help_invitations pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                <b-sidebar right id="help_invitations" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                    <div class="px-3 py-2">
                        <b-list-group>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_1") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_2") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_3") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_4") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_5") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_6") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.invitations_7") }}</b-list-group-item>                            
                        </b-list-group>
                    </div>
                </b-sidebar>
                <b-button class="button-margin mb-3" variant="primary" to="/app/invitation/new"><b-icon icon="envelope-open" />{{ $t("invitation.btn_invitation_new") }}</b-button>
                <div class="table-scrollable">
                    <b-table striped hover :items="invitations" :fields="fields">
                        <template #cell(name)="data">
                            <template><b-badge pill variant="white" class="p-1 mr-1" @click="copyText(data.item.link)" v-b-tooltip title="Einladungslink kopieren"><b-icon icon="clipboard-plus"></b-icon></b-badge></template>
                            <a :href="data.item.link">{{ data.item.name }}</a>
                        </template>
                        <template #cell(auction_string)="data">
                            <span v-for="(aitem, index) in data.item.auctions">
                                <span v-if="index != 0"><br /></span>{{ aitem.display_name }}
                            </span>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" :to="'/app/invitation/edit/' + data.item.id" ><v-icon name="edit"></v-icon></b-link>
                            <b-link class="m-1" href="#"  @click.prevent="requestConfirmation(data.item)"><v-icon name="trash"></v-icon></b-link>
                        </template>
                    </b-table>
                </div>
                <br />
                <p>{{ $t("invitation.instruction1") }}</p>
                <p>{{ $t("invitation.instruction2") }}</p>
            </b-card>
        </div>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`invitation.modal_delete`)">
        <div class="d-block text-center">
            <p v-if="invitationContext">{{ $t("invitation.modal_deleteinvitation1") }} <strong>'{{invitationContext.name}}'</strong>{{ $t("invitation.modal_deleteinvitation2") }}</p>
        </div>
        <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete">{{ $t("invitation.btn_delete") }}</b-button>
    </b-modal>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'invitation-list',
    mixins: [dateHelper],
    data() {
        return {
            invitationContext: null,
        }
    },
    created() {
        this.fetchInvitations();
    },
    watch: {
        '$route': 'fetchInvitations'
    },
    methods: {
        ...mapActions({
            fetchInvitations: 'invitations/fetchInvitations',
            deleteInvitation: 'invitations/delete'
        }),
        requestConfirmation(invitation) {
            this.invitationContext = invitation;
            this.$refs.confirmationModal.show();
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
            this.$notify({
                group: 'global',
                title: 'Einladungslink in Zwischenablage kopiert',
                type: 'success',
                text: this.text,
                autoHideDelay: 1500
            });
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteInvitation(this.invitationContext.id)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("invitation.notify_delete_title"),
                        type: 'success',
                        text: this.$t("invitation.notify_delete_message")
                    });

                    this.invitationContext = null;
                });
        },
    },
    computed: {
        ...mapGetters({
            invitations: 'invitations/invitations'
        }),
        fields() {
            return [
                {
                    key: 'id',
                    label: 'Id'
                },
                {
                    key: 'name',
                    label: 'Bezeichnung',
                    sortable: true
                },
                {
                    key: 'user.name',
                    label: 'Erstellt von',
                    sortable: true
                },
                {
                    key: 'max_uses',
                    label: 'Max. Verwendung',
                    sortable: true
                },
                {
                    key: 'count_uses',
                    label: 'Verwendung',
                    sortable: true
                },
                {
                    key: 'expires_at',
                    label: 'Gültig bis',
                    sortable: true,
                    formatter: this.dateTimeFormatter
                },
                {
                    key: 'auction_string',
                    label: 'Vergaben'
                },
                {
                    key: 'actions',
                    label: 'Aktionen'
                }
            ];
        },
    }
};
</script>
