<template>
    <div class="card" style="border-width: 0px; box-shadow: none;">
        <div class="card-header px-0" style="background-color: #fff">
            Dashboard
        </div>
        <div class="card-body px-0" style="background-color: #fff">
            <div class="home">
                <b-card :title="$t(`dashboard.header1`)" class="mb-3" v-if="currentUser && (!currentUser.accepted_dsgvo || !currentUser.accepted_terms_of_service)">
                    <p>
                        {{ $t("dashboard.profile_note1") }} <router-link to="/app/profile" href="#">{{ $t("dashboard.profile_note2") }}</router-link>{{ $t("dashboard.profile_note3") }}
                    </p>
                    <b-button class="button-margin my-2" to="/app/profile" href="#">{{ $t("dashboard.profile_update") }}</b-button>
                    <b-alert variant="danger" :show="!currentUser.accepted_terms_of_service">
                        {{ $t("dashboard.accept_termsofservice") }}
                    </b-alert>
                    <b-alert variant="danger" :show="!currentUser.accepted_dsgvo">
                        {{ $t("dashboard.accept_dsgvo") }}
                    </b-alert>
                </b-card>
            
                <b-card :title="$t(`dashboard.profile`)" class="mb-3" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <br/>
                    <router-link to="/app/profile">{{ $t("dashboard.profile_update") }}</router-link>
                    <br />
                </b-card>
            
                <b-card :title="$t(`dashboard.actual_auctions`)" class="mb-3" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        <br/>
                        <div class="table-scrollable">
                            <b-table striped hover :items="auctions" :fields="auctionFields">
                                <template #cell(title)="data">
                                    <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                                </template>
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                    {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">
                                        -
                                    </template>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date)}}
                                </template>
                            </b-table>
                        </div>
                        <br/>
                    </b-card-text>
                </b-card>
            
                <b-card :title="$t(`dashboard.actual_callforbids`)" class="mb-3" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        <br/>
                        <div class="table-scrollable">
                            <b-table striped hover :items="callForBids" :fields="callForBidsFields">
                                <template #cell(title)="data">
                                    <router-link :to="'/app/ausschreibung/view/'+data.item.id" href="#">{{data.item.title}}</router-link>
                                </template>
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                    {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">
                                        -
                                    </template>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date)}}
                                </template>
                            </b-table>
                        </div>
                        <br/>
                    </b-card-text>
                </b-card>
            
                <b-card :title="$t(`dashboard.card_contracts`)" class="mb-3" v-if="currentUser && currentUser.accepted_dsgvo && currentUser.accepted_terms_of_service">
                    <b-card-text>
                        {{ $t("dashboard.card_contracts_text") }}
                    </b-card-text>
                </b-card>
                <br />

                <template>
                    <div>
                    <b-button v-b-toggle.sidebar-variant v-if="hasRole('Administrator')"><b-icon icon="asterisk" />Icon Sidebar</b-button>
                    <b-sidebar id="sidebar-variant" title="Iconauswahl" bg-variant="dark" text-variant="light" width="800px" shadow>
                        <div class="px-3 py-2">
                            <template>
                                <div>
                                    <b-button-toolbar> 
                                        <b-button-group class="m-1">
                                            <b-button title="Neue Ausschreibung anlegen" to="/app/auktionen/new">
                                                <v-icon name="plus-circle" />
                                            </b-button>
                                            <b-button title="Einladungslink erstellen" to="/app/invitation/new">
                                                <v-icon name="envelope" />
                                            </b-button>
                                            <b-button title="Neuen Benutzer anlegen" to="/app/user/new">
                                                <v-icon name="user-plus" />
                                            </b-button>
                                            <b-button title="Neuen Fragebogen anlegen" to="/app/questionnaires/new">
                                                <v-icon name="question" />
                                            </b-button>
                                        </b-button-group>
                                    </b-button-toolbar>                
                                </div>
                            </template>
                        </div>
                    </b-sidebar>
                    </div>
                </template>     
            </div>
        </div>
    </div>
</template>
<script>
import callForBidsService from '../../../../services/CallForBidsService';
import userService from '../../../../services/UserService';
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'dashboard',
    data() {
        return {
            doneLoading: false,
            auctions: [],
            callForBids: [],
        }
    },
    created() {
        this.$store.commit('loading/setLoading', true);

        callForBidsService.fetchActiveAuctions()
            .then(auctionsResponse => {
                this.auctions = auctionsResponse.data;

                return callForBidsService.fetchActiveCallForBids()
                    .then(callForBidsResponse => {
                        this.callForBids = callForBidsResponse.data;

                        this.doneLoading = true;
                        this.$store.commit('loading/setLoading', false);
                    });
            })

    },
    methods: {
        getAuctionStatus(startDate) {
            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return this.$t(`dashboard.status_waiting`)
            }

            return this.$t(`dashboard.status_running`);
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            currentUser: 'auth/user'
        }),
        auctionFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`dashboard.label_auction`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`dashboard.label_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`dashboard.label_startdate`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`dashboard.label_enddate`),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t(`dashboard.label_status`)
                }
            ];
        },
        callForBidsFields() {
            return [
                {
                    key: 'title',
                    label: this.$t(`dashboard.label_callforbids`),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t(`dashboard.label_organizer`),
                    sortable: true
                },
                {
                    key: 'start_date',
                    label: this.$t(`dashboard.label_startdate`),
                    sortable: true
                },
                {
                    key: 'end_date',
                    label: this.$t(`dashboard.label_enddate`),
                    sortable: true
                },
                {
                    key: 'status',
                    label: this.$t(`dashboard.label_status`)
                }
            ];
        },

    }
};
</script>

