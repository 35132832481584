import store from '../../store/store';
import InvitationService from '../../services/InvitationService';

const state = {
    invitations:  [],
    auctions: []
};

const getters = {
    invitations() {
        return state.invitations;
    },
    auctions() {
        return state.auctions;
    }
};

const actions = {
    fetchInvitations({commit}) {
        store.dispatch('loading/setLoading', true);
        return InvitationService.list()
            .then(data =>{
                commit("setInvitations", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchAuctions({commit}) {
        store.dispatch('loading/setLoading', true);
        return InvitationService.availableAuctions()
            .then(data =>{
                commit("setAuctions", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, invitationId) {
        store.dispatch('loading/setLoading', true);
        return InvitationService.delete(invitationId)
            .then(data => {
                store.dispatch("invitations/fetchInvitations");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setInvitations', []);
        commit('setAuctions', []);
    }
}

const mutations = {
    setInvitations(state, invitations) {
        state.invitations = invitations;
    },
    setAuctions(state, auctions) {
        state.auctions = auctions;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
