<template>
    <b-badge pill variant="white" class="p-0" v-b-tooltip :title="value ? tooltip_true: tooltip_false">
        <b-icon :icon="value ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon>
    </b-badge>
</template>

<script>
export default {
    name: 'status-pill',
    props: ['value', 'tooltip_true', 'tooltip_false'],
}
</script>