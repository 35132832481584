<template>
<div class="card" style="border-width: 0px; box-shadow: none;">
    <div class="card-header px-0" style="background-color: #fff">
        {{ $t("user.header") }}
    </div>
    <div class="card-body px-0" style="background-color: #fff">
        <b-form @submit.prevent="onSubmit" v-show="doneLoading">
            <b-tabs>
                <b-tab :title="$t(`user.tab_editprofile`)" active>
                    <b-card :title="$t(`user.card_editprofile`)" class="mb-3">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                    <b-form-input id="user-password" type="password" v-model="user.password" :state="!$v.user.password.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                    <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false"></b-form-input>
                                </b-form-group>


                                <b-form-group :label="$t(`user.role`)" label-for="user-role">
                                    <b-form-select text-field="name" value-field="id" :options="roles" v-model="selectedRole" :state="!$v.selectedRole.$error ? null : false"></b-form-select>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false"></b-form-input>
                                </b-form-group>


                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit"></b-form-input>
                                </b-form-group>

                            </b-col>
                            <b-col>

                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo"></b-img>
                                    </template>
                                    <p class="text-primary"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..."></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :no-resize="true" :rows="3" :max-rows="3" :state="!$v.user.address.$error ? null : false"></b-form-textarea>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales" :state="!$v.user.sales.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.productgroup`)" label-for="user-product-group">
                                    <b-form-input id="user-product-group" type="text" v-model="user.product_group"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.reviewstate`)" label-for="user-review-state">
                                    <b-form-select name="user-review-state" v-model="user.review_status" >
                                        <option value="1">{{ $t("user.reviewstate_1") }}</option>
                                        <option value="2">{{ $t("user.reviewstate_2") }}</option>
                                        <option value="3">{{ $t("user.reviewstate_3") }}</option>
                                        <option value="4">{{ $t("user.reviewstate_4") }}</option>
                                        <option value="5">{{ $t("user.reviewstate_5") }}</option>
                                        <option value="6">{{ $t("user.reviewstate_6") }}</option>
                                        <option value="7">{{ $t("user.reviewstate_7") }}</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group :label="$t(`user.notes`)" label-for="user-notes">
                                    <b-form-textarea id="user-notes" v-model="user.notes" :rows="5" :max-rows="5"></b-form-textarea>
                                </b-form-group>

                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>

                <b-tab :title="$t(`user.tab_supplierevaluation`)">
                    <b-card-group deck>
                        <b-card title="Basisinformationen" class="mb-3">
                            <b-row><b-col>
                                <b-form-group label="Geheimhaltungsvereinbarung (NDA)" label-for="nondisclosure-status">
                                    <b-form-select id="nondisclosure-status" placeholder="Status zur Geheimhaltungsvereinbarung" v-model="user.nondisclosure_status" rows="1">
                                        <option value="1">{{ $t("user.nondisclosurestate_1") }}</option>
                                        <option value="2">{{ $t("user.nondisclosurestate_2") }}</option>
                                        <option value="3">{{ $t("user.nondisclosurestate_3") }}</option>
                                        <option value="4">{{ $t("user.nondisclosurestate_4") }}</option>
                                        <option value="5">{{ $t("user.nondisclosurestate_5") }}</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col></b-row>
                            <b-row><b-col>
                                <b-form-group label="Lieferantenselbstauskunft" label-for="selfdisclosure">
                                    <b-form-select id="selfdisclosure" placeholder="Status zur Lieferantenselbstauskunft" v-model="user.selfdisclosure" rows="1">
                                        <option value="1">{{ $t("user.selfdisclosure_1") }}</option>
                                        <option value="2">{{ $t("user.selfdisclosure_2") }}</option>
                                        <option value="3">{{ $t("user.selfdisclosure_3") }}</option>
                                        <option value="4">{{ $t("user.selfdisclosure_4") }}</option>
                                        <option value="5">{{ $t("user.selfdisclosure_5") }}</option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col></b-row>
                            <b-row><b-col>
                                <b-form-group label="Audit Status" label-for="audit-status">
                                    <b-form-textarea id="audit-status" placeholder="Notizen zum Status des Auditprozesses..." v-model="user.audit_status" rows="4" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>
                        </b-card>
                        <b-card title="Beschwerdemanagement" class="mb-3">
                            <b-row><b-col>
                                <b-form-group label="Prozessbeschreibung Beschwerdemanagement" label-for="complaints-procedure">
                                    <b-form-textarea id="complaints-procedure" placeholder="Beschreibung der Prozesse im Umgang mit eingehenden Beschwerden zu Beanstandungen der Einhaltung von Menschenrechten und Nachhaltigkeitsvorgaben" v-model="user.complaints_procedure" rows="6" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>
                        </b-card>
                        <b-card title="Risikomanagement" class="mb-3">
                            <b-row><b-col>
                                <b-form-group label="Risikosteuerung" label-for="risk-management">
                                    <b-form-textarea id="risk-management" placeholder="Beschreibung der inividuellen Risikosteuerung beim Lieferanten" v-model="user.risk_management" rows="4" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>

                            <b-row><b-col>
                                <b-form-group label="Risikoeinstufung Land" label-for="risk-level-country">
                                    <b-form-textarea id="risk-level-country" placeholder="Bewertung der landesbezogenen Risiken" v-model="user.risk_level_country" rows="3" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>

                            <b-row><b-col>
                                <b-form-group label="Risikoeinstufung Rohstoff" label-for="risk-level-rawmaterial">
                                    <b-form-textarea id="risk-level-rawmaterial" placeholder="Bewertung der rohstoffbezogenen Risiken" v-model="user.risk_level_rawmaterial" rows="3" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>

                            <b-row><b-col>
                                <b-form-group label="Risikoeinstufung allgemein" label-for="risk-level">
                                    <b-form-textarea id="risk-level" placeholder="Beschreibung zur individuellen Risikoeinstufung und Empfehlungen" v-model="user.risk_level" rows="3" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>
                        </b-card>
                        <b-card title="CSR-Einstufung" class="mb-3">
                            <b-row><b-col>
                                <b-form-group label="Verhaltenskodex" label-for="code-of-conduct">
                                    <b-form-textarea id="code-of-conduct" placeholder="Ausführungen zum Verhaltenskodex" v-model="user.code_of_conduct" rows="6" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>

                            <b-row><b-col>
                                <b-form-group label="Nachhaltigkeit und Umweltschutz" label-for="environmental-issues">
                                    <b-form-textarea id="environmental-issues" placeholder="Beschreibung der Nachhaltigkeitsmaßnahmen und des Umweltschutzes" v-model="user.environmental_issues" rows="5" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>

                            <b-row><b-col>
                                <b-form-group label="Menschenrechte" label-for="human-rights-issues">
                                    <b-form-textarea id="human-rights-issues" placeholder="Beschreibung der Maßnahmen zur Einhaltung der Meschenrechte" v-model="user.human_rights_issues" rows="6" /></b-form-textarea>
                                </b-form-group>
                            </b-col></b-row>
                        </b-card>
                    </b-card-group>
                </b-tab>

            </b-tabs>
            <br />
            <b-button class="button-margin" variant="primary" type="submit"><b-icon icon="journal-plus" />{{ $t("user.btn_save") }}</b-button>
        </b-form>
    </div>
</div>
</template>
<script>
import userService from '../../../../services/UserService';
import roleService from '../../../../services/RoleService';
import { helpers, required, email, sameAs , numeric} from 'vuelidate/lib/validators';

export default {
    name: 'user-list',
    data() {
        return {
            doneLoading: false,
            roles:[],
            selectedRole: null,
            user: {
            },
            file: null
        }
    },
    created() {
        return this.fetchRoles()
            .then(() => {
                this.$store.commit('loading/setLoading', false);
                this.doneLoading = true;
            });
    },
    validations: {
        user: {
            contact: {
                required
            },
            phone: {
                required
            },
            company: {
                required
            },
            address: {
                required
            },
            employee_count: {
                required, numeric
            },
            sales: {
                required
            },
            name: {
                required
            },
            password: {
                required
            },
            password_repeat: {
                required,
                sameAsPassword: sameAs('password')
            },
            email: {
                required,
                email
            },
        },
        selectedRole: {
            required
        }
    },
    methods: {
        fetchRoles() {
            return roleService.list()
                .then(roleData => {
                    this.roles = roleData.data;
                })
        },
        onSubmit() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.user.role = this.selectedRole;

                userService.create(JSON.stringify(this.user), this.file)
                    .then(response => {
                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notifiy_success_title"),
                                type: 'success',
                                text: this.$t("user.notifiy_success_message")
                            });

                            this.$router.push({path: '/app/users'});
                        }

                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notify_error_title"),
                                type: 'error',
                                text: response.data.message
                            });
                        }
                    })
                    .catch(error => {
                    this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_error_title"),
                            type: 'error',
                            text: error.message
                        });
                    });
            }
        }
    },
    computed: {

    }
};
</script>
