import store from '../../store/store';
import UserService from '../../services/UserService';

const state = {
    users:  []
};

const getters = {
    users() {
        return state.users;
    }
};

const actions = {
    fetchUsers({commit}) {
        store.dispatch('loading/setLoading', true);
        return UserService.list()
            .then(data =>{
                commit("setUsers", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, userId) {
      
        store.dispatch('loading/setLoading', true);
        return UserService.delete(userId)
            .then(data => {
                store.dispatch("users/fetchUsers");
                store.dispatch('loading/setLoading', false);
            });            
    },
    clear({commit}) {
        commit('setUsers', []);
    }
}

const mutations = {
    setUsers(state, users) {    
        state.users = users;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};