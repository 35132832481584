export const transformToFormData = {
    methods: {
        /**
        * Simple transformation to form data for uploading files
        * using VueJS and Axios.
        *
        * Assuming that you pass your entire form as the `form`
        * paramter. For example:
        *
        * data(){
        *      return {
        *          form : {
        *              name: 'Dan',
        *              job: 'Software Developer'
        *              website: 'https://serversideup.net'
        *              logo: FileList
        *          }
        *      }
        * }
        *
        * this.transfromToFormData( this.form, ['logo'] );
        *
        * For updating and sending a PUT request add:
        *
        * this.transformToFormData( this.form, ['logo'], 'PUT' );
        *
        * When sending a form as form data, you might need to send as
        * POST, but pass a _method parameter for 'PUT'.
        *
        *
        *
        * @param {object} form The object representation of a form.
        * @param {array} fileKeys An array of keys that represent files
        * @param {string} method Optional: The method used to send the form such as 'PUT'
        */
        transformToFormData(form, fileKeys, method = '') {
            // Initializes the form dat object that we will be appending to
            let formData = new FormData();

            // Iterates over all elements in the form. Adds them to the
            // form data object. If the value is a file, we append the
            // file to the form data.
            for (let [key, value] of Object.entries(form)) {
                if ((fileKeys != null) && (fileKeys.indexOf(key) > -1)) {
                    if (value !== '' ) {
                        formData.append(key, value[0]);
                    }
                } else {
                    // Booleans don't send as a true boolean through form data.
                    // We send it as a 1 or 0 to make for easier processing on the
                    // backend
                    if (typeof value === "boolean") {
                        value = value ? 1 : 0;
                    }
                    if (value !== null) {
                        formData.append(key, value);
                    }
                }
            }

            // If we have a method we need to send as, we append it here
            if (method != '') {
                formData.append('_method', method);
            }

            return formData;
        },

    }
}
