<template>
    <div class="card" style="border-width: 0px; box-shadow: none;">
        <div class="card-header px-0" style="background-color: #fff">
            {{ $t("user.header_profile") }} <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
        </div>
        <div class="card-body px-0" style="background-color: #fff">
            <b-tabs>
                <b-tab :title="$t(`user.tab_selfdisclosure`)" >
                    <b-container>
                        <template v-for="questionnaire in questionnaires">
                            <b-row v-bind:key="questionnaire.id">
                                <b-col>
                                    <QuestionnaireComponent :questionnaire="questionnaire" :answers="answers" />
                                </b-col>
                            </b-row>
                        </template>
                    </b-container>
                </b-tab>
            </b-tabs>
        </div>
        <b-modal id="termsOfService" ref="termsOfService" :title="$t('profile.termsofservicetitle')" size="lg" :cancelDisabled="true">
            <template slot="modal-footer">
                <b-button class="button-margin" @click="$refs.termsOfService.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
            </template>
            <TermsOfServiceComponent />
        </b-modal>
    </div>
    </template>
    <script>
    import userService from '../../../../services/UserService';
    import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
    import TermsOfServiceComponent from '../../content/TermsOfService';
    import QuestionnaireService from '../../../../services/QuestionnaireService';
    import DownloadService from '../../../../services/DownloadService';
    import QuestionnaireComponent from './Questionnaire';
    
    export default {
        name: 'profile',
        components: {
            TermsOfServiceComponent,
            QuestionnaireComponent
        },
        data() {
            return {
                doneLoading: false,
                hideAgreements: false,
                questionnaires: [],
                answers: {},
                user: {},
                file: {}
            }
        },
        validations() {
            let validations = {
                answers: {}
            };
    
            this.questionnaires.forEach(q => {
                q.questions.forEach(question => {
                    validations['answers'][question.id] = {};
    
                    if(question.required) {
                        validations['answers'][question.id]['required'] = required;
                    }
    
                    if(question.check_regex) {
                        const customValidator = (value) => {
                            if(value === undefined || value === null) {
                                return true;
                            }
    
                            return new RegExp(question.check_regex).test(value);
                        }
    
                        validations['answers'][question.id]['regex'] = customValidator;
                    }
    
                    if(question.check_eval) {
                         const customValidator = (value) => {
                            if(value === undefined || value === null || value === '') {
                                return true;
                            }
    
                            return eval(''+value+question.check_eval);
                        }
    
                        validations['answers'][question.id]['eval'] = customValidator;
                    }
                });
            });
    
            return validations;
        },
        created() {
            this.loadUserProfile();
        },
        validations: {
            user: {
                name: {
                    required
                },
                password_repeat: {
                    sameAsPassword: sameAs('password')
                },
                contact: {
                    required
                },
                phone: {
                    required
                },
                company: {
                    required
                },
                address: {
                    required
                },
                employee_count: {
                    required, numeric
                },
                sales: {
                    required
                },
                email: {
                    required,
                    email
                }
            }
        },
        methods: {
            loadUserProfile() {
                this.$store.commit('loading/setLoading', true);
    
                userService.fetchProfile()
                    .then(userResponse => {
                      this.user = userResponse.data;
    
                      if(this.user.accepted_dsgvo && this.user.accepted_terms_of_service) {
                        this.hideAgreements = true;
                      }
    
                      return QuestionnaireService.fetchForMe()
                          .then(response => {
                            this.questionnaires = response.data;
    
                            return QuestionnaireService.fetchMyAnswers()
                                .then(answers => {
                                  this.$store.commit('loading/setLoading', false);
                                  this.answers = answers.data;
                                  this.doneLoading = true;
                                });
                          });
                    })
            },
            submitForm(questionnaireId) {
                let questionnaire;
                this.$v.$touch();
    
                if(this.$v.$error) {
                    return;
                }
                this.questionnaires.some(qn => {
                    if(qn.id === questionnaireId) {
                        questionnaire = qn;
                        return true;
                    }
                })
    
                let values = {};
    
                questionnaire.questions.forEach(q => {
                    values[q.id] = this.answers[q.id];
                });
    
                QuestionnaireService.saveQuestions(questionnaireId, values)
                    .then(response => {
                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notify_questionnaire_save"),
                                type: 'success',
                                text: this.$t("user.notify_questionnaire_message")
                            });
                        }
                    });
            },
            onSubmitUserInfo() {
                this.$v.$touch();
    
                if(this.$v.$error) {
                    return;
                }
                userService.updateProfile(this.user, this.file)
                    .then(response => {
                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notify_profile_save"),
                                type: 'success',
                                text: this.$t("user.notify_profile_message")
                            });
                            // TODO: Does not work yet
                            this.loadUserProfile();
                        } else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notify_profile_error"),
                                type: 'error',
                                text: this.$t("user.notify_profile_errormessage")
                            });
                        }
                    });
            },
            showTermsOfService() {
                this.$refs.termsOfService.show();
            },
            downloadQuestionnaire(questionnaireId) {
                QuestionnaireService.downloadQuestionnaire(questionnaireId)
                    .then(response => {
                        const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'fragebogen.pdf');
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    });
            },
            downloadFile(downloadKey, filename, mimetype) {
                QuestionnaireService.downloadFile(downloadKey)
                    .then(response => {
                        DownloadService.processDownload(response, filename, mimetype);
                    });
            }
        },
        computed: {
            fieldState() {
                return quesionId => this.$v['question-'+quesionId].$error ? 'valid' : false;
            }
        }
    };
    </script>
    