<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <b-row class="lg_frame mb-5 mx-0 p-3">BESCHAFFUNGSPLATTFORM</b-row>
        <span v-html="$t('presentation.costmanagement')" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'kostenmanagement',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>
