import store from '../../store/store';
import QuestionnaireService from '../../services/QuestionnaireService';

const state = {
    questionnaires:  []
};

const getters = {
    questionnaires() {
        return state.questionnaires;
    }
};

const actions = {
    list({commit}) {
        store.dispatch('loading/setLoading', true);
        return QuestionnaireService.list()
            .then(data =>{
                commit("setQuestionnaires", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    clear({commit}) {
        commit('setQuestionnaires', []);
    }
}

const mutations = {
    setQuestionnaires(state, questionnaires) {    
        state.questionnaires = questionnaires;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};