<template>
    <div>
      <h4>Übersicht Einspareffekte</h4>
      <line-chart :chart-data="datacollection" :height="100"></line-chart>
    </div>
</template>

<script>
  
  import LineChart from './charts/LineChart.js'
  
  export default {
    components: {
      LineChart
    },
    data(){
      return {
        datacollection: null
      }
    },
    mounted () {
      this.fillData()
    },
    methods: {
  
      fillData ()
      {
        this.datacollection = {
          labels: ['Jan','Feb','Mrz','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
          datasets: [
            {
              label: 'Einsparsumme',
              backgroundColor: '#00aae8',
              data: [ 20, 30, 40, 50, 55, 70, 80, 90, 100, 105, 105, 120]
            },
          ]
        }
      }
    }
  }
</script>
