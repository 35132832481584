import store from '../../store/store';
import CallForBidsService from '../../services/CallForBidsService';

const state = {
    auctions:  [],
    auctions_archived: [],
    callForBids: [],
    callForBids_archived: [],
    organizers: []
};

const getters = {
    auctions() {
        return state.auctions;
    },
    callForBids() {
        return state.callForBids;
    },
    auctions_archived() {
        return state.auctions_archived;
    },
    callForBids_archived() {
        return state.callForBids_archived;
    },
    organizers() {
        return state.organizers;
    }
};

const actions = {
    fetchAuctions({commit}) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.list(true, false)
            .then(data =>{
                commit("setAuctions", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchCallForBids({commit}) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.list(false, false)
            .then(data =>{
                commit("setCallForBids", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchArchivedAuctions({commit}) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.list(true, true)
            .then(data =>{
                commit("setAuctions_archived", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchArchivedCallForBids({commit}) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.list(false, true)
            .then(data =>{
                commit("setCallForBids_archived", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchOrganizers({commit}) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.fetchOrganizers()
            .then(data => {
                commit('setOrganizers', data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, auctionId) {
        store.dispatch('loading/setLoading', true);
        return CallForBidsService.delete(auctionId)
            .then(data => {
                store.dispatch("callForBids/fetchAuctions");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setAuctions', []);
        commit('setCallForBids', []);
        commit('setAuctions_archived', []);
        commit('setCallForBids_archived', []);
        commit('setOrganizers', []);
    }
}

const mutations = {
    setAuctions(state, auctions) {
        state.auctions = auctions;
    },
    setAuctions_archived(state, auctions) {
        state.auctions_archived = auctions;
    },
    setOrganizers(state, organizers) {
        state.organizers = organizers;
    },
    setCallForBids(state, callForBids) {
        state.callForBids = callForBids;
    },
    setCallForBids_archived(state, callForBids) {
        state.callForBids_archived = callForBids;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
