<template>
    <div class="home">
        <h4>Lieferantendatenbanken</h4>
        <p>
        Folgende Lieferanten- und Unternehmensdatenbanken liefern im Rahmen der Beschaffungsmarktforschung alle wesentlichen Kontaktinformationen.
        </p>
        <br />

        <b-card-group deck footer-tag="footer">
            <b-card title="Kompass GmbH"
                img-src="images/database/Kompass.png"           
                img-top
                class="">
                <p class="card-text">
                    Firmendatenbank mit über 40 Mio. Lieferanten.<br/>
                    Fokus: weltweit<br/><br/>
                </p>
                <template #footer>
                    <a href="https://de.kompass.com/">KOMPASS GmbH</a>
                </template>
            </b-card>
            <b-card title="EUROPAGES"
                img-src="images/database/europages.png"           
                img-top
                class="">
                <p class="card-text">
                    Unternehmensdatenbank mit ca. 3 Mio. Lieferanten, insbesondere KMUs.<br/>
                    Fokus: Europa, KMUs<br/><br/>
                </p>
                <template #footer>
                    <a href="https://www.europages.de/">EUROPAGES</a>
                </template>
            </b-card>
            <b-card title="Thomasnet.com"
                img-src="images/database/Thomas.png"           
                img-top
                class="">
                <p class="card-text">
                    Zugang zu über 500.000 Lieferanten<br/>
                    Fokus: Nord Amerika<br/><br/>
                </p>
                <template #footer>
                    <a href="https://business.thomasnet.com">Thomasnet.com</a>
                </template>
            </b-card>
        </b-card-group>
        <br/>

        <b-card-group deck footer-tag="footer">
            <b-card title="Lieferanten.de"
                img-src="images/database/Lieferantende.png"           
                img-top
                class="">
                <p class="card-text">
                    Datenbank mit ca. 11.000 Herstellern und über 6.000 Großhändlern<br/>
                    Fokus: Deutschland<br/><br/>
                </p>
                <template #footer>
                    <a href="https://www.lieferanten.de/">Lieferanten.de</a>
                </template>
            </b-card>
            <b-card title="asiansources"
                img-src="images/database/asiansources.png"           
                img-top
                class="">
                <p class="card-text">
                    Lieferantensuchmaschine und Online-Marktplatz<br/>
                    Fokus: Asien, China <br/><br/>
                </p>
                <template #footer>
                    <a href="https://www.asiansources.com/">asiansources</a>
                </template>
            </b-card>
            <b-card title="techpilot"
                img-src="images/database/techpilot.png"           
                img-top
                class="">
                <p class="card-text">
                    Plattform für Einkäufer und Lieferanten für die Fertigungsindustrie<br/>
                    Fokus: Europa, Deutschland <br/><br/>
                </p>
                <template #footer>
                    <a href="https://www.techpilot.de/">techpilot</a>
                </template>
            </b-card>
        </b-card-group>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'supplierresearch',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>