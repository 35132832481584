import axios from 'axios';
var querystring = require('querystring');

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetch(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts', formData)
            .then(response => response)
            .catch(error => error.response);
    },
    update(contractId, payload) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId, querystring.stringify(payload),
                         { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
            .then(response => response)
            .catch(error => error.response);
    },
    delete(contractId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchSelectionLists() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/selectionLists')
            .then(response => response.data)
            .catch(error => error.response);
    },

    availableAuctions() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/auctions')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchAuctions(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/getauctions/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    assignAuction(contractId, auctionId) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignauction/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    unassignAuction(contractId, auctionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/unassignauction/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },


    createContractFile(contractId, payload) {
        var formData = new FormData();
        formData.append('file', payload.file);
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteContractFile(contractId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadContractFile(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/contractFile', { responseType:"blob" })
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },


    createAppendix(contractId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listAppendices(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateAppendix(contractId, appendixId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId, payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteAppendix(contractId, appendixId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadAppendix(contractId, appendixId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/appendix/' + appendixId + '/download', { responseType:"blob" })
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },


    searchUser(query) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/usersearch/' + query)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    availableUsers() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/users')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchUsers(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/getusers/' + contractId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    addUser(contractId, user) {
        return axios.put(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignedUsers/' + user, '')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    unassignUser(contractId, user) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/assignedUsers/' + user, '')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getAssignedUsers(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/'+ contractId + '/assignedUsers')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },

    listReminders(contractId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    createReminder(contractId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateReminder(contractId, contractreminderId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder/' + contractreminderId , payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response)); 
    },
    deleteReminder(contractId, contractreminderId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/contracts/' + contractId + '/reminder/' + contractreminderId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response)); 
    },
    fetchActiveReminders() {
        return axios.get(window.applicationSettings.apiEndpoint + '/contracts/activeReminders')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
