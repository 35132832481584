import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/projects/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetch(projectId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/projects/show/' + projectId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/projects/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(projectId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/projects/update/' + projectId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    delete(projectId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/projects/delete/' + projectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveProjectUp(projectId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/projects/moveUp/' + projectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveProjectDown(projectId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/projects/moveDown/' + projectId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
