<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <b-row class="lg_frame mb-5 mx-0 p-3">BESCHAFFUNGSPLATTFORM</b-row>

        <p class="lg_title" v-html="$t('presentation.procurement_title')" />
        <p class="lg_subtitle" v-html="$t('presentation.procurement_subtitle')" />
        <span class="lg_text" v-html="$t('presentation.procurement_text')" />
        <p class="lg_list" v-html="$t('presentation.procurement_list')" />
        <span class="lg_text" v-html="$t('presentation.procurement_text2')" />
        <p class="lg_list" v-html="$t('presentation.procurement_list2')" />
        <span class="lg_text" v-html="$t('presentation.procurement_text3')" />
        <a class="lg_link" href="mailto: beschaffung@landgard.de">beschaffung(at)landgard.de</a>
        <br />
        <br />
        <span class="lg_text" v-html="$t('presentation.procurement_text4')" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'einkauf',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>