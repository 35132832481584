<template>
    <div class="loader" v-show="isLoading"></div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isLoading: 'loading/isLoading'
        })
    }
}
</script>