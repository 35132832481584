
import store from '../../store/store';
import ContractService from '../../services/ContractService';

const state = {
    contracts: [],
    auctions: [],
    users: [],
    appendixTypes: [],
    contractConfidentialities: [],
    contractExtensionTypes: [],
    contractOccasions: [],
    contractRiskLevels: [],
    contractStates: [],
    contractStrategicImportances: [],
    contractSubjects: [],
    contractTerminationForms: [],
    contractTypes: [],
    contractValueClusters: [],
    resubmissionReasons: [],
    resubmissionStates: [],
    //callForBids: [],
    //organizers: []
};

const getters = {
    contracts() {
        return state.contracts;
    },
    auctions() {
        return state.auctions;
    },
    users() {
        return state.users;
    },
    appendixTypes() {
        return state.appendixTypes;
    },
    contractConfidentialities() {
        return state.contractConfidentialities;
    },
    contractExtensionTypes() {
        return state.contractExtensionTypes;
    },
    contractOccasions() {
        return state.contractOccasions;
    },
    contractRiskLevels() {
        return state.contractRiskLevels;
    },
    contractStates() {
        return state.contractStates;
    },
    contractStrategicImportances() {
        return state.contractStrategicImportances;
    },
    contractSubjects() {
        return state.contractSubjects;
    },
    contractTerminationForms() {
        return state.contractTerminationForms;
    },
    contractTypes() {
        return state.contractTypes;
    },
    contractValueClusters() {
        return state.contractValueClusters;
    },
    resubmissionReasons() {
        return state.resubmissionReasons;
    },
    resubmissionStates() {
        return state.resubmissionStates;
    },
};

const actions = {
    fetchContracts({commit}, archived) {
        store.dispatch('loading/setLoading', true);
        return ContractService.list(true, archived)
            .then(data =>{
                commit("setContracts", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchAuctions({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.availableAuctions()
            .then(data =>{
                commit("setAuctions", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchUsers({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.availableUsers()
            .then(data => {
                commit('setUsers', data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    fetchSelectionLists({commit}) {
        store.dispatch('loading/setLoading', true);
        return ContractService.fetchSelectionLists()
            .then(data => {
                // Todo: Weitere Daten im Store ablegen
                commit('setAppendixTypes', data.data.appendixTypes);
                commit('setContractConfidentialities', data.data.contractConfidentialities);
                commit('setContractExtensionTypes', data.data.contractExtensionTypes);
                commit('setContractOccasions', data.data.contractOccasions);
                commit('setContractRiskLevels', data.data.contractRiskLevels);
                commit('setContractStates', data.data.contractStates);
                commit('setContractStrategicImportances', data.data.contractStrategicImportances);
                commit('setContractSubjects', data.data.contractSubjects);
                commit('setContractTerminationForms', data.data.contractTerminationForms);
                commit('setContractTypes', data.data.contractTypes);
                commit('setContractValueClusters', data.data.contractValueClusters);
                commit('setResubmissionReasons', data.data.resubmissionReasons);
                commit('setResubmissionStates', data.data.resubmissionStates);
                store.dispatch('loading/setLoading', false);
            });
    },

    // TODO: Hier wird commit und data in "grau" angezeigt. Fehlt hier noch etwas?
    delete({commit}, contractId) {
        store.dispatch('loading/setLoading', true);
        return ContractService.delete(contractId)
            .then(data => {
                store.dispatch("contracts/fetchContracts");
                store.dispatch('loading/setLoading', false);
            });
    },
    clear({commit}) {
        commit('setContracts', []);
        commit('setUsers', []);
        commit('setAuctions', []);
        commit('setAppendixTypes', []);
        commit('setContractConfidentialities', []);
        commit('setContractExtensionTypes', []);
        commit('setContractOccasions', []);
        commit('setContractRiskLevels', []);
        commit('setContractStates', []);
        commit('setContractStrategicImportances', []);
        commit('setContractSubjects', []);
        commit('setContractTerminationForms', []);
        commit('setContractTypes', []);
        commit('setContractValueClusters', []);
        commit('setResubmissionReasons', []);
        commit('setResubmissionStates', []);
    }
}

const mutations = {
    setContracts(state, contracts) {
        state.contracts = contracts;
    },
    setAuctions(state, auctions) {
        state.auctions = auctions;
    },
    setUsers(state, users) {
        state.users = users;
    },
    setAppendixTypes(state, appendixTypes) {
        state.appendixTypes = appendixTypes;
    },
    setContractConfidentialities(state, contractConfidentialities) {
        state.contractConfidentialities = contractConfidentialities;
    },
    setContractExtensionTypes(state, contractExtensionTypes) {
        state.contractExtensionTypes = contractExtensionTypes;
    },
    setContractOccasions(state, contractOccasions) {
        state.contractOccasions = contractOccasions;
    },
    setContractRiskLevels(state, contractRiskLevels) {
        state.contractRiskLevels = contractRiskLevels;
    },
    setContractStates(state, contractStates) {
        state.contractStates = contractStates;
    },
    setContractStrategicImportances(state, contractStrategicImportances) {
        state.contractStrategicImportances = contractStrategicImportances;
    },
    setContractSubjects(state, contractSubjects) {
        state.contractSubjects = contractSubjects;
    },
    setContractTerminationForms(state, contractTerminationForms) {
        state.contractTerminationForms = contractTerminationForms;
    },
    setContractTypes(state, contractTypes) {
        state.contractTypes = contractTypes;
    },
    setContractValueClusters(state, contractValueClusters) {
        state.contractValueClusters = contractValueClusters;
    },
    setResubmissionReasons(state, resubmissionReasons) {
        state.resubmissionReasons = resubmissionReasons;
    },
    setResubmissionStates(state, resubmissionStates) {
        state.resubmissionStates = resubmissionStates;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
