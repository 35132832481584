    
<template>
    <div class="card" style="border-width: 0px; box-shadow: none;">
        <div class="card-header px-0" style="background-color: #fff">
            {{ $t("legal.header") }}
        </div>

        <div class="card-body px-0" style="background-color: #fff">
            <b-tabs pills card vertical px-0 nav-wrapper-class="w-25">
                <b-tab active>
                    <template #title>
                        <b-icon icon="telephone-outbound"></b-icon>{{ $t("legal.tab_1") }}
                    </template>
                    <template>
                        <b-card class="mb-2"  v-b-toggle.1>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_1") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="1">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.2>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_2") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="2">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.3>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_3") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="3">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.4>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_4") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="4">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.5>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_5") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="5">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                    </template>
                </b-tab>
                
                <b-tab>
                    <template #title>
                        <b-icon icon="grid"></b-icon>{{ $t("legal.tab_2") }}
                    </template>
                    <template>
                        <b-card class="mb-2"  v-b-toggle.1>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_1") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="1">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.2>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_2") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="2">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.3>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_3") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="3">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.4>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_4") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="4">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.5>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_5") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="5">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                    </template>
                </b-tab>
                
                <b-tab>
                    <template #title>
                        <b-icon icon="building"></b-icon>{{ $t("legal.tab_3") }}
                    </template>
                    <template>
                        <b-card class="mb-2"  v-b-toggle.1>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_1") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="1">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.2>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_2") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="2">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.3>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_3") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="3">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.4>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_4") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="4">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class=" mb-2"  v-b-toggle.5>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_5") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="5">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                    </template>
                </b-tab>
                
                <b-tab>
                    <template #title>
                        <b-icon icon="wrench"></b-icon>{{ $t("legal.tab_4") }}
                    </template>
                    <template>
                        <b-card class="mb-2"  v-b-toggle.1>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_1") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="1">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.2>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_2") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="2">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.3>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_3") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="3">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.4>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_4") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="4">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.5>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_5") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="5">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                    </template>
                </b-tab>
                
                <b-tab>
                    <template #title>
                        <b-icon icon="people"></b-icon>{{ $t("legal.tab_5") }}
                    </template>
                    <template>
                        <b-card class="mb-2"  v-b-toggle.1>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_1") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="1">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.2>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_2") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="2">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                
                        <b-card class="mb-2"  v-b-toggle.3>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_3") }}
                            <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="3">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.4>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_4") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="4">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>

                        <b-card class="mb-2"  v-b-toggle.5>
                            <span class="d-flex align-items-center">
                                {{ $t("legal.accordion_5") }}
                                <b-icon class="ml-auto" icon="chevron-down"></b-icon>
                            </span>
                            <b-collapse id="5">
                                <hr class="mb-0 navbar-vertical-divider" />  
                                <div class="mt-3">
                                    <span v-html="$t('legal.loremipsum')" />
                                    <span v-html="$t('legal.loremipsum_list')" />
                                </div>
                            </b-collapse>
                        </b-card>
                    </template>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'legalsupport',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>