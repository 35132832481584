import store from '../../store/store';
import ProjectService from '../../services/ProjectService';

const state = {
    projects:  []
};

const getters = {
    projects() {
        return state.projects;
    }
};

const actions = {
    fetchProjects({commit}) {
        store.dispatch('loading/setLoading', true);
        return ProjectService.list()
            .then(data =>{
                commit("setProjects", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    list({commit}) {
        store.dispatch('loading/setLoading', true);
        return ProjectService.list()
            .then(data =>{
                commit("setProjects", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, projectId) {
        store.dispatch('loading/setLoading', true);
        return ProjectService.delete(projectId)
            .then(data => {
                store.dispatch("projects/fetchProjects");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setProjects', []);
    }
}

const mutations = {
    setProjects(state, projects) {    
        state.projects = projects;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};