import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/invitation/list')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetch(invitationId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/invitation/get/'+invitationId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(invitationId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/invitation/update/'+invitationId, formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/invitation/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    delete(invitationId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/invitation/delete/'+ invitationId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    availableAuctions() {
        return axios.get(window.applicationSettings.apiEndpoint + '/invitation/auctions')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchAuctions(invitationId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/invitation/getauctions/'+invitationId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    assignAuction(invitationId, auctionId) {
        return axios.put(window.applicationSettings.apiEndpoint + '/invitation/assignauction/'+ invitationId + '/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    unassignAuction(invitationId, auctionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/invitation/unassignauction/'+ invitationId + '/' + auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
}
