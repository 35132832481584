import store from '../../store/store';
import Auth from '../../services/AuthService';

export default (to, from, next) => {
    const loggedIn = store.getters['auth/loggedIn'];

    if(!loggedIn) {
        return next();
    }

    store.dispatch('loading/setLoading', true);
    store.dispatch('auth/authenticate').then(() => {
        store.dispatch('loading/setLoading', false);
        next();
    }).catch((error) => {
        store.dispatch('loading/setLoading', false);
        store.dispatch('auth/logout');
        next('/login');
    });

};
