<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <b-row class="lg_frame mb-5 mx-0 p-3">BESCHAFFUNGSPLATTFORM</b-row>
        
        <p class="lg_title" v-html="$t('presentation.team_title')" />
        <p class="lg_subtitle" v-html="$t('presentation.team_subtitle')" />
        <p class="lg_text" v-html="$t('presentation.team_text')" />
        <b-card-group deck>
            <b-card title="Marcel Wenzel"
                img-src="images/team/Wenzel.jpg"
                img-top
                style="max-width: 20rem;">
                <p class="card-text">
                    Bereichsleiter<br/><br/>
                    <a href="mailto: marcel.wenzel@landgard.de">Marcel.Wenzel@landgard.de</a><br/>
                    Tel. +49 2839 59-1593<br/>
                    Mob. +49 1622 408 928<br/>
                    <br />
                    Landgard Service GmbH<br/>
                    Veilingstraße T1200<br/>
                    3. OG<br/>
                    47638 Straelen-Herongen<br/>
                </p>
            </b-card>
            <b-card title="Swen Pichenet"
                img-src="images/team/Pichenet.jpg"
                img-top
                style="max-width: 20rem;">
                <p class="card-text">
                    Technischer Einkäufer<br/><br/>
                    <a href="mailto: swen.pichenet@landgard.de">Swen.Pichenet@landgard.de</a><br/>
                    Tel. +49 2839 59-1589<br/>
                    Mob. +49 173 7278 293<br/>
                    <br />
                    Landgard Service GmbH<br/>
                    Veilingstraße T1200<br/>
                    3. OG<br/>
                    47638 Straelen-Herongen<br/>
                </p>
            </b-card>
            <b-card title="Christian Linke"
                img-src="images/team/Linke.jpg"
                img-top
                style="max-width: 20rem;">
                <p class="card-text">
                    Sachbearbeiter Einkauf<br/><br/>
                    <a href="mailto: christian.linke@landgard.de">Christian.Linke@landgard.de</a>
                    <br/>
                    Tel. +49 2839 59-1588<br/>
                    <br />
                    Landgard Service GmbH<br/>
                    Veilingstraße T1200<br/>
                    3. OG<br/>
                    47638 Straelen-Herongen<br/>
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>