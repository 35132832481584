<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <b-row class="lg_frame mb-5 mx-0 p-3">BESCHAFFUNGSPLATTFORM</b-row>

        <p class="lg_title" v-html="$t('presentation.home_title')" />
        <p class="lg_subtitle" v-html="$t('presentation.home_subtitle')" />
        <span class="lg_text" v-html="$t('presentation.home_text')" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'home',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>