<template>
<div class="card" style="border-width: 0px; box-shadow: none;">
    <div class="card-header px-0" style="background-color: #fff">
        {{ $t("user.header_profile") }} <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
    </div>
    <div class="card-body px-0" style="background-color: #fff">
        <b-tabs>
            <b-tab :title="$t(`user.tab_editprofile`)" active>
                <b-card :title="$t(`user.card_editprofile`)" class="mb-3">
                    <b-form @submit.prevent="onSubmitUserInfo" v-show="doneLoading">
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                        <b-form-input id="user-password" type="password" v-model="user.password"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                        <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit"></b-form-input>
                                </b-form-group>

                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_terms_of_service" class="terms-text">
                                    {{ $t("user.accept_termsofservice_1") }}<a href="#" @click.prevent="showTermsOfService">{{ $t("user.accept_termsofservice_2") }}</a>.
                                </b-form-checkbox>
                                <b-form-checkbox v-if="!hideAgreements" v-model="user.accepted_dsgvo" class="terms-text">
                                    {{ $t("user.accept_dsgvo") }}
                                </b-form-checkbox>

                            </b-col>
                            <b-col>
                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo" class="preview-image"></b-img>
                                    </template>
                                    <p class="text-primary" v-if="!user.logo"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..."></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :state="!$v.user.address.$error ? null : false" :no-resize="true" :rows="3" :max-rows="3"></b-form-textarea>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales" :state="!$v.user.sales.$error ? null : false"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-button class="button-margin" variant="primary" type="submit"><b-icon icon="save" />{{ $t("user.btn_save") }}</b-button>
                    </b-form>
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
    <b-modal id="termsOfService" ref="termsOfService" :title="$t('profile.termsofservicetitle')" size="lg" :cancelDisabled="true">
        <template slot="modal-footer">
            <b-button class="button-margin" @click="$refs.termsOfService.hide()"><b-icon icon="check2" />{{ $t("user.modal_btn_ok") }}</b-button>
        </template>
        <TermsOfServiceComponent />
    </b-modal>
</div>
</template>
<script>
import userService from '../../../../services/UserService';
import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
import TermsOfServiceComponent from '../../content/TermsOfService';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';
import QuestionnaireComponent from './Questionnaire';

export default {
    name: 'profile',
    components: {
        TermsOfServiceComponent,
        QuestionnaireComponent
    },
    data() {
        return {
            doneLoading: false,
            hideAgreements: false,
            questionnaires: [],
            answers: {},
            user: {},
            file: {}
        }
    },
    validations() {
        let validations = {
            answers: {}
        };

        this.questionnaires.forEach(q => {
            q.questions.forEach(question => {
                validations['answers'][question.id] = {};

                if(question.required) {
                    validations['answers'][question.id]['required'] = required;
                }

                if(question.check_regex) {
                    const customValidator = (value) => {
                        if(value === undefined || value === null) {
                            return true;
                        }

                        return new RegExp(question.check_regex).test(value);
                    }

                    validations['answers'][question.id]['regex'] = customValidator;
                }

                if(question.check_eval) {
                     const customValidator = (value) => {
                        if(value === undefined || value === null || value === '') {
                            return true;
                        }

                        return eval(''+value+question.check_eval);
                    }

                    validations['answers'][question.id]['eval'] = customValidator;
                }
            });
        });

        return validations;
    },
    created() {
        this.loadUserProfile();
    },
    validations: {
        user: {
            name: {
                required
            },
            password_repeat: {
                sameAsPassword: sameAs('password')
            },
            contact: {
                required
            },
            phone: {
                required
            },
            company: {
                required
            },
            address: {
                required
            },
            employee_count: {
                required, numeric
            },
            sales: {
                required
            },
            email: {
                required,
                email
            }
        }
    },
    methods: {
        loadUserProfile() {
            this.$store.commit('loading/setLoading', true);

            userService.fetchProfile()
                .then(userResponse => {
                  this.user = userResponse.data;

                  if(this.user.accepted_dsgvo && this.user.accepted_terms_of_service) {
                    this.hideAgreements = true;
                  }

                  return QuestionnaireService.fetchForMe()
                      .then(response => {
                        this.questionnaires = response.data;

                        return QuestionnaireService.fetchMyAnswers()
                            .then(answers => {
                              this.$store.commit('loading/setLoading', false);
                              this.answers = answers.data;
                              this.doneLoading = true;
                            });
                      });
                })
        },
        submitForm(questionnaireId) {
            let questionnaire;
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            this.questionnaires.some(qn => {
                if(qn.id === questionnaireId) {
                    questionnaire = qn;
                    return true;
                }
            })

            let values = {};

            questionnaire.questions.forEach(q => {
                values[q.id] = this.answers[q.id];
            });

            QuestionnaireService.saveQuestions(questionnaireId, values)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_questionnaire_save"),
                            type: 'success',
                            text: this.$t("user.notify_questionnaire_message")
                        });
                    }
                });
        },
        onSubmitUserInfo() {
            this.$v.$touch();

            if(this.$v.$error) {
                return;
            }
            userService.updateProfile(this.user, this.file)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_save"),
                            type: 'success',
                            text: this.$t("user.notify_profile_message")
                        });
                        // TODO: Does not work yet
                        this.loadUserProfile();
                    } else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_profile_error"),
                            type: 'error',
                            text: this.$t("user.notify_profile_errormessage")
                        });
                    }
                });
        },
        showTermsOfService() {
            this.$refs.termsOfService.show();
        },
        downloadQuestionnaire(questionnaireId) {
            QuestionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            QuestionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        }
    },
    computed: {
        fieldState() {
            return quesionId => this.$v['question-'+quesionId].$error ? 'valid' : false;
        }
    }
};
</script>
