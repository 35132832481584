import Vue from 'vue';
import axios from 'axios';

export default {
    getCsrfcookie() {
        return axios.get('sanctum/csrf-cookie')
            .then(response => true)
            .catch(error => Promise.reject(error.response));
    },
    login(credentials) {
        return axios.post(window.applicationSettings.apiEndpoint + '/auth/login', credentials)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    authenticate() {
        return axios.post(window.applicationSettings.apiEndpoint + "/auth/me")
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    requestReset(user) {
        return axios.post(window.applicationSettings.apiEndpoint + "/auth/request", user)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    reset(user) {
        return axios.post(window.applicationSettings.apiEndpoint + "/auth/reset", user)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    logout() {
        return axios.post(window.applicationSettings.apiEndpoint + "/auth/logout")
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
