import axios from "axios";;
import AuthService from '../../services/AuthService';

const state = {
    loggedIn: false,
    user: null,
};

const getters = {
    user() {
        return state.user;
    },
    loggedIn() {
        return state.loggedIn
    },
    auth () {
		return state;
    },
    hasRole: (state) => (roleName) => {
        return state.user && state.user.assigned_roles.some(x => x === roleName);
    }
};

const actions = {
    login({commit}, credentials) {
        return AuthService.login(credentials)
        .then(data => {
            commit("setLoggedIn", true)            
        })
    },
    requestReset({commit}, auser) {
        return AuthService.requestReset(auser)
    },
    reset({commit}, auser) {
        return AuthService.reset(auser)
    },
    logout({commit, dispatch}) {
       return AuthService.logout()
            .then(data => {
                dispatch("clearAll", null, {root: true});
                commit("setUser", null);
                commit("setLoggedIn", false);
            })
            .catch(error => {
                commit("setUser", null);
                commit("setLoggedIn", false);
            });
    },
    authenticate({commit}) {
        return AuthService.authenticate().then(data => {
            commit("setUser", data);
            commit("setLoggedIn", true);
        });
    },
    clear({commit}) {
        commit("setUser", null);
    }
};

const mutations = {
    setLoggedIn(state, status) {
        state.loggedIn = status;
    },
    setUser(state, user) {
        state.user = user;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
