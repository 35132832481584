<template>
  <div id="sidebarMenu" class="col-lg-2 col-md-3 d-md-block sidebar collapse scrollbar show">
    <b-button-close @click="smToggle()" class="p-3 d-md-none"></b-button-close>
    <img src="images/logo.png" height="40" class="mx-auto d-flex justify-content-between my-3 align-middle" alt="">
    <div v-if="!loggedIn">
      <div class="position-sticky pl-1 pt-1">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-muted">
          <span>Vorstellung</span>
          <div class="col ps-0">
            <hr class="mb-0 navbar-vertical-divider">
          </div>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <b-link to="/einkauf" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="building"></b-icon></span> {{ $t("nav.einkauf") }}
            </b-link>
          </li>
<!--
          <li class="nav-item">
            <b-link to="/kostenmanagement" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="cash-coin"></b-icon></span> {{ $t("nav.kostenmanagement") }}
            </b-link>
          </li>
-->
          <li class="nav-item">
            <b-link to="/team" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="person-square"></b-icon></span> {{ $t("nav.team") }}
            </b-link>
          </li>
        </ul>
      </div>
      <div class="position-sticky pl-1 pt-1">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-muted">
          <span>Funktionen Beschaffungsplattform</span>
          <div class="col ps-0">
            <hr class="mb-0 navbar-vertical-divider">
          </div>
        </h6>
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <b-link to="/auktionen" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="cart-check"></b-icon></span> {{ $t("nav.auktionen") }}
            </b-link>
          </li>
          <li class="nav-item">
            <b-link to="/ausschreibungen" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="shield-exclamation"></b-icon></span> {{ $t("nav.ausschreibungen") }}
            </b-link>
          </li>
        </ul>
      </div>
      <br />
      <div class="position-sticky pl-1 pt-1">
        <hr class="mb-3 navbar-vertical-divider">
        <ul class="nav flex-column mb-2">
          <li class="nav-item">
            <b-link to="/login" @click="smToggle" class="nav-link" href="#">
              <span class="nav-link-icon"><b-icon icon="box-arrow-in-right"></b-icon></span> {{ $t("nav.login") }}
            </b-link>
          </li>
          <li class="nav-item"  v-b-toggle.language>
            <b-collapse id="language">
              <template v-for="(lang,code) in supportedLocales">
                <a class="dropdown-item ml-5" href="#" @click="smToggle" @click.prevent="$i18n.locale=code">{{ lang }}</a>
              </template>
            </b-collapse>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="loggedIn && currentUser">  
      <div class="position-sticky pl-1 pt-1">
        <div>
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-muted">
            <span>Ausschreibungs- und Auktions-Management</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/auktionen" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="cart-check"></b-icon></span> {{ $t("nav.app_auktionen") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/ausschreibungen" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="shield-exclamation"></b-icon></span> {{ $t("nav.app_ausschreibungen") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/invitations" @click="smToggle" v-if="hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="envelope"></b-icon></span> {{ $t("nav.invitations") }}
              </b-link>
            </li>
          </ul>
        </div>

        <div v-if="hasRole('Administrator') || hasRole('Berater')">
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Vertrags-Management</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/contracts" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="file-text"></b-icon></span> {{ $t("nav.contracts") }}
                </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/legalsupport" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="chat-left-text"></b-icon></span> {{ $t("nav.legalsupport") }}
              </b-link>
            </li>
          </ul>
        </div>

        <div v-if="hasRole('Administrator') || hasRole('Berater') || hasRole('Erweiterter Einkäufer')">
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Projekt-Management</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/projects" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="bar-chart-steps"></b-icon></span> {{ $t("nav.projects") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/controlling" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="bar-chart-line"></b-icon></span> {{ $t("nav.controlling") }}
              </b-link>
            </li>
          </ul>
        </div>

        <div v-if="hasRole('Administrator') || hasRole('Berater') || hasRole('Erweiterter Einkäufer')">
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Lieferanten-Management</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/questionnaires" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="question-square"></b-icon></span> {{ $t("nav.questionnaires") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/supplychainact" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="bar-chart-steps"></b-icon></span> {{ $t("nav.supplychainact") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/supplierresearch" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')||hasRole('Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="geo-alt"></b-icon></span> {{ $t("nav.supplierresearch") }}
              </b-link>
            </li>
          </ul>
        </div>

        <div v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')||hasRole('Einkäufer')">
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>{{ $t("nav.navbarDropdownInternal") }}</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/users" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="person"></b-icon></span> {{ $t("nav.users") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/activitylog" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="clock-history"></b-icon></span> {{ $t("nav.activitylog") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/administration/bids" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="pen"></b-icon></span> {{ $t("nav.administration_bids") }}
              </b-link>
            </li>
          </ul>
        </div>

        <div>
          <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>Profil</span>
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider">
            </div>
          </h6>
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/app/dashboard" @click="smToggle" class="nav-link">
                <span class="nav-link-icon"><b-icon icon="pie-chart"></b-icon></span> {{ $t("nav.dashboard") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/profile" @click="smToggle" class="nav-link">
                <span class="nav-link-icon"><b-icon icon="person-check"></b-icon></span> {{ $t("nav.profile") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/app/selfdisclosure" @click="smToggle" class="nav-link">
                <span class="nav-link-icon"><b-icon icon="clipboard-check"></b-icon></span> {{ $t("nav.selfdisclosure") }}
              </b-link>
            </li>
            <div class="col ps-0">
              <hr class="mb-2 mt-5 navbar-vertical-divider">
            </div>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="logoutCurrentUser">
                <span class="nav-link-icon"><b-icon icon="box-arrow-right"></b-icon></span> {{ $t("nav.logout") }}
              </a>
            </li>

            <li class="nav-item"  v-b-toggle.language>
              <a class="nav-link d-flex align-items-center" href="#" id="navbarDropdownLocale" role="button" aria-expanded="false">
                <span class="nav-link-icon d-flex"><b-icon icon="globe2"></b-icon></span>
                <b-icon class="ml-auto" icon="chevron-right"></b-icon>
              </a>
              <b-collapse id="language">
                <template v-for="(lang,code) in supportedLocales">
                  <a class="dropdown-item ml-5" href="#" @click="smToggle" @click.prevent="$i18n.locale=code">{{ lang }}</a>
                </template>
              </b-collapse>
            </li>
            <li>
              <br />
              <div class="col ps-0">
                <hr class="mb-0 navbar-vertical-divider">
              </div>
              <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-5 text-muted">{{ loggedInUserEmail }}</h6>
            </li>
          </ul>
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localeHelper from '../helpers/localeHelper';
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
        hasRole: 'auth/hasRole',
        loggedIn: 'auth/loggedIn',
        currentUser: 'auth/user',
    }),
    loggedInUserEmail: function() {
        return this.currentUser ? this.currentUser.email : '';
    },
    supportedLocales() {
        return localeHelper.supportedLocales;
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    toggleactive(){
      document.getElementById('sidebarMenu').classList.toggle('active');
      document.getElementById('app').classList.toggle('active');
    },
    smToggle() {
      var screenWidth = window.innerWidth;
      if (screenWidth < 768) {
          document.getElementById("sidebarMenu").classList.toggle('active');
      }
    },
    logoutCurrentUser () {
      this.logout().then(() => {
          return this.$router.push({path:'/login'});
      });
    }
  }
}
</script>
