export const dateHelper = {
    methods: {
        dateFormatter(value) {
            if(value == null) {
                return "";
            }
            let parsed = this.$moment(value);
            parsed.locale(this.$i18n.locale);
            return parsed.format('L');;
        },
        dateTimeFormatter(value) {
            if(value == null) {
                return "";
            }
            let parsed = this.$moment(value);
            parsed.locale(this.$i18n.locale);
            return parsed.format('L LT');;
        },
        dateTimeCombine(adate, atime) {
            if ((adate == null) || (atime == null)) {
                return null;
            }

            let ntime = this.$moment('2000-01-01 ' + atime);
            let result = this.$moment(adate);
            result.hour(ntime.hour()).minute(ntime.minute());
            return result;
        }
    }
}
