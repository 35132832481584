<template>
<div class="card mb-5">
    <div class="card-header">{{ $t("forgotpassword.header") }}</div>
    <div class="card-body">
	<form>
        <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label text-md-right">{{ $t("forgotpassword.email") }}</label>

            <div class="col-md-6">
                <input id="email" type="email" class="form-control" name="email" v-model="user.email" required autofocus>
            </div>
        </div>

        <div class="form-group row mb-0">
            <div class="col-md-8 offset-md-4">
                <b-button class="button-margin mt-5" type="submit" @click.prevent="sendRequest(user)">
                    {{ $t("forgotpassword.sendrequest") }}
                </b-button>
            </div>
        </div>
	</form>
    </div>
</div>

</template>

<script>

import { mapActions } from 'vuex';
import Auth from '../../services/AuthService';

export default {
    name: 'forgotpassword',
    data() {
        return {
            user: {
                email: ''
            }
        }
    },
    methods: {
        ...mapActions({
            requestReset: 'auth/requestReset'
        }),
        sendRequest (user) {
            this.requestReset(user)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("forgotpassword.header"),
                        type: 'success',
                        text: this.$t("forgotpassword.success")
                    });
                    this.$router.push({path: '/'});
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("forgotpassword.header"),
                        type: 'error',
                        text: this.$t("forgotpassword.error")
                    });
                });
        }
    }
};

</script>

