export default {
    supportedLocales : {
        de: "Deutsch",
        en: "English",
//        pt: "Português",
    },
    getBrowserLocale(options = {}) {
        const defaultOptions = { countryCodeOnly: false }
        const opt = { ...defaultOptions, ...options }
        const navigatorLocale =
            navigator.languages !== undefined
                ? navigator.languages[0]
                : navigator.language
        if (!navigatorLocale) {
            return undefined
        }
        const trimmedLocale = opt.countryCodeOnly
            ? navigatorLocale.trim().split(/-|_/)[0]
            : navigatorLocale.trim()
        return trimmedLocale
    },
    getSupportedLocales() {
        let annotatedLocales = []
        for (const code of Object.keys(this.supportedLocales)) {
            annotatedLocales.push({
            code,
            name: this.supportedLocales[code]
            })
        }
        return annotatedLocales
    },
    supportedLocalesInclude(locale) {
        return Object.keys(this.supportedLocales).includes(locale)
    }
}

