
export default {
    processDownload(response, fileName, mimetype) {
        if(!response) { return; }

        if(!mimetype) {
            mimetype = undefined;
        }

        const url = window.URL.createObjectURL(new Blob([response], {type: mimetype}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentElement.removeChild(link);
    }
}
