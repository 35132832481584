import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/user/list')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetch(userId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/user/get/'+userId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(userId, payload, logo) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));

        if(logo) {
            formData.append('logo', logo);
        }

        return axios.post(window.applicationSettings.apiEndpoint + '/user/update/'+userId, formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload, logo) {
        var formData = new FormData();
        formData.append('data', payload);

        if(logo) {
            formData.append('logo', logo);
        }

        return axios.post(window.applicationSettings.apiEndpoint + '/user/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    delete(userId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/user/delete/'+ userId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchProfile() {
        return axios.get(window.applicationSettings.apiEndpoint+'/profile/fetch')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateProfile(payload, logo) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));

        if(logo) {
           formData.append('logo', logo);
        }

        return axios.post(window.applicationSettings.apiEndpoint+'/profile/update', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    validateInvitationLink(code) {
        return axios.post(window.applicationSettings.apiEndpoint+'/registration/validate', {code: code})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    register(payload) {
        return axios.post(window.applicationSettings.apiEndpoint+'/registration/register', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    activate(activationCode) {
        return axios.post(window.applicationSettings.apiEndpoint+'/registration/activate', {activationCode: activationCode})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
